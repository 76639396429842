




































import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import { accountRegister } from '../../../apis/account.api';
import { errorPopupHandler, errorCatchHandler, loadings } from '../../../utils/decorators/VueTimmer';
import { resetPassword } from '../../../apis/password.api';

@Component
export default class ResetPassword extends Vue {
  
  get token(){
    return this.$route.query.code
  }

  @Provide()
  form={
    password:"",
    password_repeat:""
  }

  errors = {
    reset: ""
  }

  loadings = {
    reset: false,
  }

  success = false

  @errorCatchHandler("errors.reset", "Fail to reset password","form")
  @loadings("loadings.reset")
  onSubmit(){
    return resetPassword({
      password: this.form.password,
      token: this.token
    }).then(() => {
      this.success = true
    })
  }

  onReset(){
    
  }
}
